import React from 'react'

/**
 * list of Statistics
 * @param {Array} data -(required) [{number: string, title: string, class: string, logo: image}]
 *
 */

export const StatisticsSlider = ({ data }) => {
  return (
    <section className="products-homepage logos tw-bg-bottom tw-bg-no-repeat tw-bg-cover tw-mt-[52px] md:tw-mt-[76px] content-visibility-auto-mobile">
          <div className="container">
            <div className="columns parent-column tw-flex tw-flex-row tw-overflow-x-auto">
              {data?.map((content, i) => {
                let isLast = i == (data.length - 1)
                return (
                  <div key={i} className="column tw-p-[8px] tw-relative tw-min-w-[309px] md:tw-min-w-max md:tw-w-[25%]">
                    <div className={"products-card-line tw-flex tw-flex-col tw-justify-between tw-relative tw-h-full !tw-shadow-none !tw-bg-transparent tw-text-center tw-m-[0px_auto] tw-p-[20px_5px] tw-no-underline after:tw-content-[''] after:tw-absolute after:tw-right-0 after:tw-w-[2px] after:tw-h-[55%] after:tw-top-[50px] after:tw-bg-[#999]" + (isLast ? "products-card-line-last after:tw-content-[''] after:tw-hidden" : "")}>                      
                      <div>
                        <div className="image-header tw-bg-accruent_dark_imp_blue tw-rounded-full
                          tw-w-[145px] tw-h-[145px] tw-relative tw-m-[0px_auto]">
                          <span className="tw-text-mobileXxlFont md:tw-text-xxlFont tw-absolute tw-top-1/2 tw-left-1/2 tw-bg-none tw-text-accruent_white
                           tw-translate-x-[-50%] tw-translate-y-[-50%] !tw-font-Poppins">{content?.number}</span>
                        </div>
                        <div className="card-description tw-m-0 tw-mt-4 tw-tracking-[1.8px] tw-text-accruent-base-color
                          tw-uppercase tw-text-[12px] tw-leading-4 md:tw-max-w-[180px] md:tw-ml-auto md:tw-mr-auto md:tw-items-center">
                          <p className='tw-mb-[1.15rem] tw-font-Roboto'>{content.title}</p>
                        </div>
                      </div>
                      <div className={`bottom-image tw-w-full tw-ml-auto tw-mr-auto tw-min-h-[73px] ${content.class}`} >
                        {content.logo}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
  )
}

